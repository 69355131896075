import React from "react"
import Fade from 'react-reveal/Fade';
import AnchorLink from 'react-anchor-link-smooth-scroll'


import "./hero.css"

const Hero = () => (
  <div className="hero">
    <Fade left distance="5rem">
      <div className="hero--left"></div>
    </Fade>
     <div className="hero--right">
     <Fade top cascade distance="1.5rem">
        <div className="hero--right-content">
            <h1 className="gold">Vahva tunnistautuminen, Verkkokaupat, varausjärjestelmät ja verkkoliiketoiminnan integraatiot</h1>
            <strong>Liikkeesi verkossa</strong>
            <p>Nykyaikainen verkkoliiketoiminta tapahtuu verkkokaupassa. Tunne, jonka saavutat astuessasi esimerkiksi ihailemasi brändin kivijalkakauppaan, on mahdollista herättää myös online-ostokokemuksen myötä.</p>
            <ul>
               <li>Tarkistamme verkkoliiketoimintasi tilan</li>
               <li>Hiomme ja uudistamme</li>
               <li>Toteutamme tarvittavat toimenpiteet</li>
            </ul>
            
            <AnchorLink offset='100' href='#partner'>
             <svg width="32" height="32" version="1.1" viewBox="0 0 32 32"><g transform="scale(1.33333)"><path fill="none" stroke="#121212" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M16.5,18.497l-5,5l-5,-5"></path><path fill="none" stroke="#121212" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M11.5,23.5v-23"></path></g></svg>
            </AnchorLink>

        </div>
        </Fade>
     </div>
  </div>
)


export default Hero
 