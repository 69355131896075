import React from "react"

import Container from "../container/container"
import "./bg.css"


const BG = () => (
   <div className="background">
      <Container>
         <div className="background--item"></div> 
         <div className="background--item"></div> 
         <div className="background--item"></div> 
         <div className="background--item"></div> 
      </Container>
   </div>
)


export default BG
