import React from "react" 
import Fade from 'react-reveal/Fade';


import Container from "../container/container"
import "./footer.css"


const Footer = () => (
   <footer>
       <div className="border-top"></div>
      <div className="border-bottom"></div> 
      <div className="border-left"></div>
      <div className="border-right"></div> 

      <Container>
      <Fade top distance="1rem">

         <div className="footer">
            <div className="footer--left">
               <h3>Sovi tapaaminen</h3>
               <p>Mitä saat tapaamiselta:</p>

               <ul>
                  <li>Tiedon, onko sinulla taloudellisesti ja tehokkaasti sinulle sopivin tekninen toteutus käytössäsi</li>
                  <li>Verkkokauppasi vision kirkastuminen</li>
                  <li>Suuntaviivoja potentiaaliselle kasvulle ja kehitykselle</li>
               </ul>
               
            </div>
            <div className="footer--right">
                  <h3>Ota yhteyttä</h3>
                  <p>Kalle Kaskinen // Sales Director <br /> <a className="contact-link" href="tel:+358505858596">+358 50 5858 596</a> <a className="contact-link" href="mailto:kalle.kaskinen@meiko.fi">kalle.kaskinen@meiko.fi</a></p>

                  <form action="https://formspree.io/xgddqgrm" method="POST">  
                     <input type="hidden" name="_next" value="https://osta-verkkokauppa.meiko.fi/lomake-lahetetty"/>
                     <div className="gotcha">  
                        <input type="text" name="_gotcha" />
                     </div>
                     <input type="text" name="nimi" placeholder="Nimesi" />
                     <input type="text" name="yhteystieto" placeholder="Sähköposti tai puhelin" />
                     <input type="submit" value="Lähetä" />
                  </form>

            </div>
         </div>
         </Fade>
   </Container>
  </footer>
)


export default Footer
