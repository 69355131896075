import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';

import "./reference.css"

import slideImg1 from "../../images/masku.jpg"
import slideImg2 from "../../images/mft.jpg"
import slideImg4 from "../../images/sopimustieto.jpg"


const Reference = () => {

  const [slideNum, setSlideNum] = useState(0); 
  const [show, setShow] = useState(true);

  const slides = [
    {
      title: "Masku",
      subtitle: "Perheyrityksen suurin yksittäinen myyntikanava",
      content: "Maskun ja Meikon yhteinen taival on alkanut jo vuonnna 2012 ja jatkuu edelleen tähän päivään. Tarve toimivalle online-myyntikanavalle toi Meikon mukaan kehitykseen, jossa koko verkkoliiketoiminta on rakennettu vastaamaan tämän päivän tarpeita. Alkuun taustalla käytetty Meikon kehittämä Kontrol2-järjestelmä mahdollisti nopean verkkokaupan julkaisun ja tärkeimmät toiminallisuudet kuten tuote-, sivuston-, sisällön- ja asiakashallinnan sekä asiakastuen. Meiko toimii edelleen Maskun verkkoliiketoiminnan kumppanina, joka ratkoo ja kehittää järjestelmää päivittäisellä tasolla.",
      link: "https://www.masku.com/",
      img: slideImg1
    },
    {
      title: "My Favorite Things",
      subtitle: "Trilogy Oy",
      content: "Helsinkiläinen skeittauksen erikoisliike - My Favorite Things on tehnyt yhteistyötä Meikon kanssa jo vuodesta 2011. Yhteistyö aloitettiin verkkokaupalla ja sen yhdistämisellä kivijalkaliikkeen kassajärjestelmään. Vuoden 2019 alussa verkkokauppa päätettiin siirtää uuteen ympäristöön ja kehittää prosesseja entisestään. Alustaksi valikoitiin Shopify, josta löytyivät työkalut niin verkkokaupan kuin kivijalan tarpeisiin.",
      link: "https://myfavoritethings.fi/",
      img: slideImg2
    },
    {
      title: "Sopimustieto",
      subtitle: "Juridisesti pätevät sopimukset digitaalisesti",
      content: "Tulevaisuuden sopimuspalvelu Suomen Sopimustieto pyrkii tuomaan helpon sopimisen kaikkien saataville tarjoamalla käyttäjille älykkään Sopimuskoneen, SopimusSign-allekirjoittamisen ja automaattisen ja turvallisen arkiston samassa paketissa. Meiko on alusta asti kehittänyt Sopimustiedon digitaalista alustateknologiaa jatkuvalla sopimuksella tarjoten jatkuvasti kehittyvälle palvelulle kyvyn vastata tulevaisuuden haasteisiin ja tarpeisiin. Kovassa kasvussa olevan palvelun tulee myös pystyä vastaamaan skaalautuvuuden haasteisiin. Sopimustiedon palvelu on hyvä esimerkki järjestelmästä, jossa maksupolku on täysin räätälöity asiakkaan tarpeen mukaisesti.",
      link: "https://sopimustieto.fi/",
      img: slideImg4
    },
  ]

  const decrease = () => {

    setShow(false)

    if(slideNum === 0){
      setSlideNum(slides.length - 1)
    }else{
      setSlideNum(slideNum - 1)
    }

    setTimeout(function(){ setShow(true) }, 700);

  }

  const increase = () => {

    setShow(false)


    if(slideNum === slides.length - 1){
      setSlideNum(0);

    }else{
      setSlideNum(slideNum + 1);

    }
  
    setTimeout(function(){ setShow(true) }, 700);

  }


  return(
    <Fade top distance="3rem">
    <div className="reference">
      <Fade left distance="3rem" when={show}>
        <div className="reference--left"
          style={{backgroundImage: "url(" + slides[slideNum].img + ")"}}
        ></div>
      </Fade>
      
      <div className="reference--right">
        <Fade top distance="0.5rem" when={show} opposite>
          <strong className="gold">Työmme</strong>
          <h1>{slides[slideNum].title}</h1>
          <h4>{slides[slideNum].subtitle}</h4>
          <p>{slides[slideNum].content}</p>
          <a href={slides[slideNum].link}>Vieraile sivustolla</a>
        </Fade>
          <div className="reference--right--buttons">
            <button onClick={decrease} className="reference--right--buttons--prev">
              <svg width="32" height="32" version="1.1" viewBox="0 0 32 32"><g transform="scale(1.33333)"><path fill="none" stroke="#121212" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M5.5,17.497l-5,-5l5,-5"></path><path fill="none" stroke="#121212" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M0.5,12.5h23"></path></g></svg>
            </button>
            <button onClick={increase}  className="reference--right--buttons--next">
              <svg width="32" height="32" version="1.1" viewBox="0 0 32 32"><g transform="scale(1.33333)"><path fill="none" stroke="#121212" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M18.5,7.497l5,5l-5,5"></path><path fill="none" stroke="#121212" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M23.5,12.5h-23"></path></g></svg>
            </button>
          </div>
          
      </div>
      
    </div>
    </Fade>
  )
}


export default Reference