import React from 'react';
import { useCookies } from 'react-cookie';


import "./notification.css"


const Notification = () => {

  const [cookies, setCookie] = useCookies(['notification']);

  const hideNotification = () => {
    setCookie('notification', false, { path: '/', maxAge: 86400 });
  }


  return(
    <div>
      {cookies.notification === undefined && 
      <div className="notification">
        <div className="row">
            <div className="left">
              Käytämme evästeitä parantaaksemme käyttäjäkokemusta. Käyttämällä sivustoa hyväksyt <a rel="noopener noreferrer" href="https://api.meiko.fi/wp-content/uploads/2018/10/tietosuojaseloste.pdf" target="_blank">tietosuojaselosteen.</a> 
            </div>
            <div className="right">
              <button onClick={hideNotification}>Hyväksyn</button>
            </div>
        </div> 
      </div>
      }
    </div>
  )
}


export default Notification