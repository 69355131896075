import React from "react"
import Fade from 'react-reveal/Fade';

import Container from "../container/container"
import "./partner.css"


const Partner = () => (
   <Container>
      <div className="partner">
      <Fade top cascade distance="2rem">

         <div className="partner--left" id="partner">
            <h2>Kokemus merkitsee</h2>
            <p>Kyse ei ole pelkästään siitä mitä voit koskea, vaan siitä mitä voit aidosti odottaa innoissasi ja jonka tilaaminen oli sinulle mielekästä ja helppoa. Verkkoliiketoiminta rakentuu toki toimivan logistiikan ja varastoinnin, markkinoinnin ja brändi- sekä tuoteportfolion ympärille, mutta sen estradi on aina markkinapaikka; sinun verkkokauppasi.</p>

            <strong>Teemme järjestelmäriippumattomasti*</strong>
            <ul>
               <li>Verkkoliiketoimintasi tarvekartoituksen</li>
               <li>Verkkokaupan suunnittelun ja teknisen huipputoteutuksen</li>
               <li>Integraatiot käyttämiesi järjestelmien välille</li>
            </ul>
            <small>* Emme ole sitoutuneita mihinkään yksittäiseen alustaan tai ohjelmistoon. Valitsemme aina tilanteeseen sopivimman ratkaisun.</small>
         </div>
         <div className="partner--right">

            <div className="partner--right--item">
               <span>01</span>
               <h3>Salaisuus on yksityiskohdissa</h3>
               <p>Olemme kaikki lukeneet tutkimukset ostokokemuksen merkityksestä ja sen vaikutuksista ostokäyttäytymiseen. Ihmisiin kohdistuva markkinointipenetraatioyritysten määrä päivittäisellä tasolla on valtava ja tämän soidintanssin ensimmäisen erän voittavat parhaat markkinoijat, mutta lopullisen ottelun vievät parhaat verkkokaupat.</p>
            </div>
            <div className="partner--right--item">
               <span>02</span>
               <h3>Voittava yhteistyö</h3>
               <p>Me Meikolla haluamme olla mukana tekemässä voittavia tarinoita. Tästä johtuen haastammekin sinut tarkastelemaan oman verkkoliiketoimintasi nykytilaa – onko kauppasi ostopolku mahdollisimman sujuva ja myyvä? Miten siitä voisi tehdä vielä paremman ja synnyttää tästä sinulle kilpailuedun? Haluamme käydä juuri tämän keskustelun kanssasi. Meillä on pitkä kokemus verkkoliiketoiminnan ratkaisuista ja hieman anti-suomalaisesti ollaan tästä tosi ylpeitä.</p>
            </div>
           

         </div>
         </Fade>
      </div>
  </Container>
)


export default Partner
