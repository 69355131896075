import React from "react"
import { CookiesProvider } from 'react-cookie';


import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/hero/hero"
import Partner from "../components/partner/partner"
import Reference from "../components/reference/reference"



const IndexPage = () => (
  <CookiesProvider>
    <Layout>
      <SEO title="Osta verkkokauppa" />
      <Hero />
      <Partner />
      <Reference />
    </Layout>
  </CookiesProvider>
)

export default IndexPage
